import { ReactNode } from 'react'

import { Box, BoxProps, HStack } from '@chakra-ui/react'

import { FeatureTooltipInfo } from '../types/FeatureTooltipInfo.ts'
import { FeatureButton } from './FeatureButton'
import { FeatureClickableButton } from './FeatureClickableButton'
import { FeatureIconMemo, type FeatureIconProps } from './FeatureIcon.tsx'

interface FeatureProps extends BoxProps {
	label: string | ReactNode
	isClickable?: boolean
	isExist?: boolean
	onClick?: () => void
	disableIcon?: boolean
	iconValues: FeatureIconProps
	tooltipInfo: FeatureTooltipInfo
}

export const Feature = ({
	label,
	isClickable = false,
	isExist = true,
	onClick,
	disableIcon = false,
	tooltipInfo,
	iconValues,
	...props
}: FeatureProps) => {
	const basePropsForIcon: FeatureIconProps = {
		groupIconName: iconValues.groupIconName,
		featureIconName: iconValues.featureIconName,
		optionIconName: iconValues.optionIconName,
		width: '1.5rem',
		height: '1.5rem'
	}

	return (
		<Box
			position="relative"
			display="flex"
			{...props}
		>
			{isClickable && (
				<FeatureClickableButton
					basePropsForIcon={basePropsForIcon}
					isExist={isExist}
					onClick={onClick}
					disableIcon={disableIcon}
					textAlign="left"
					data-wwt-id="feature--button"
					tooltipInfo={tooltipInfo}
				>
					{label}
				</FeatureClickableButton>
			)}

			{!isClickable && (
				<HStack
					color="gray.500"
					w="full"
				>
					{!disableIcon && (
						<FeatureIconMemo
							{...basePropsForIcon}
							color={isExist ? 'gray.500' : 'brand.100'}
						/>
					)}

					<FeatureButton
						isExist={isExist}
						basePropsForIcon={basePropsForIcon}
						tooltipInfo={tooltipInfo}
						textAlign="left"
					>
						{label}
					</FeatureButton>
				</HStack>
			)}
		</Box>
	)
}
